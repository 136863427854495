<template>
  <div class="container">
    <div class="footer-content">
      <el-row type="flex"
              v-if="$store.state.isZH"
              justify="center">
        <el-col :xs="4"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                :span="6">
          <div class="about">
            <h4 class="title">关于欧瑞捷</h4>
            <ul>
              <li v-for="item in about"
                  :data-index="item.name"
                  class="item"
                  @click="$router.push(item.path)"
                  :key="item.name">{{ item.name }}</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="4"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                :span="6">
          <div class="news">
            <h4 class="title"
                @click="$router.push('/news')">新闻中心</h4>
            <ul>
              <li class="item">参展动态</li>
            </ul>
          </div>
        </el-col>
        <el-col :span="4"
                :xs="6"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6">
          <div class="product">
            <h4 class="title"
                @click="$router.push('/product')">产品中心</h4>
            <ul>
              <li v-for="item in product"
                  class="item"
                  :key="item">{{ item }}</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="12"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                :span="8">
          <div class="call">
            <h4 class="title"
                @click="$router.push('/callUs')">联系我们</h4>
            <ul>
              <li class="item">电话:+86 592-7073986</li>
              <li class="item">传真:+86 592-7073986</li>
              <li class="item flex"><span>邮箱:</span>info@originbiotech.com.cn</li>
              <li class="item">sales@originbiotech.com.cn</li>

              <li class="item">地址:中国福建省厦门市火炬高新区(翔安)产业区(361101)</li>
            </ul>
          </div>
        </el-col>
      </el-row>

      <!-- 英文 -->
      <el-row type="flex"
              justify="space-around"
              v-if="!$store.state.isZH">
        <el-col :xs="8"
                :sm="8"
                :md="8"
                :lg="8"
                :xl="8"
                :span="8">
          <div class="news"
               :class="$store.state.isZH ? '' : 'ENsize'">
            <h4 class="ENsize"
                @click="$router.push('/aboutUs')">This is Origin</h4>
            <h4 class="ENsize"
                @click="$router.push('/news')">News & Media</h4>
            <h4 class="ENsize"
                @click="$router.push('/product')">Products</h4>
          </div>
        </el-col>
        <el-col :xs="16"
                :sm="8"
                :md="8"
                :lg="8"
                :xl="8"
                :span="8">
          <div class="call">
            <h4 class=""
                @click="$router.push('/callUs')"
                :class="$store.state.isZH ? '' : 'ENsize'">Contact</h4>
            <ul>
              <li class="item">Tel:+86 592-7073986</li>
              <li class="item">Fax:+86 592-7073986</li>
              <li class="item flex"><span>Email:</span>info@originbiotech.com.cn</li>
              <li class="item">sales@originbiotech.com.cn</li>
              <li class="item">Address: Xiang'an Torch Hi-tech Industrial Development Zone, Xiamen, Fujian, China (361101)</li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer-bgc"></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      about: [
        {
          name: '关于我们',
          path: '/aboutUs'
        }, {
          name: '发展历程',
          path: '/devHistory'
        }, {
          name: '企业荣誉',
          path: '/honor'
        }, {
          name: '销售服务',
          path: '/sales'
        }
      ],
      product: ['兽用原料药及中间体', '人用原料药及中间体', '其他在研产品']
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background-color: skyblue;
  width: 100%;
  box-sizing: border-box;
  .footer-content {
    box-sizing: border-box;
    background-color: #80005c;
    padding: 2% 22%;
    .title {
      color: white;
      font-weight: 400;
      padding-bottom: 15px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .ENsize {
      font-size: 36px;
      color: white;
      font-weight: 400;
      padding-bottom: 15px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .item {
      list-style: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
      padding-bottom: 8px;
      box-sizing: border-box;
    }
    .flex {
      display: flex;
      flex-direction: column;
    }
  }
  .footer-bgc {
    height: 3.2rem;
    background-color: #5c5c5c;
  }
}
@media only screen and (max-width: 500px) {
  .container {
    .footer-content {
      padding: 2%;
      box-sizing: border-box;
    }
    .title {
      font-size: 12px;
    }
    .ENsize {
      font-size: 25px !important;
    }
    .about .item,
    .news .item,
    .product .item,
    .call .item {
      font-size: 12px;
    }
  }
}
</style>