import Index from './data/index/index'
import Header from './data/header/index'
import Product from './data/product/index'
import About from './data/AboutUS/index'
import News from './data/news'
import Contact from './data/contact';
const zh = {
  language: {
    name: '中文'
  },
  index: {
    index: Index.ZH
  },
  header: {
    header: Header.ZH.ARR,
    MenuVal1: Header.ZH.MenuVal1,
    MenuVal2: Header.ZH.MenuVal2,
    MenuVal3: Header.ZH.MenuVal3,
    MenuVal4: Header.ZH.MenuVal4,
    MenuVal5: Header.ZH.MenuVal5,
    MenuVal6: Header.ZH.MenuVal6,
    MenuVal7: Header.ZH.MenuVal7,
    MenuVal8: Header.ZH.MenuVal8,
  },
  product: {
    Veterinary: Product.ZH.Veterinary,
    Human: Product.ZH.Human,
  },
  about: {
    aboutOrg: About.ZH.aboutOrg,
    paragraph1: About.ZH.paragraph1,
    paragraph2: About.ZH.paragraph2,
    paragraph3: About.ZH.paragraph3,
    paragraph4: About.ZH.paragraph4,
    paragraph5: About.ZH.paragraph5,
  },
  news: {
    content1: News.ZH.content1,
    content2: News.ZH.content2,
    content3: News.ZH.content3,
    content4: News.ZH.content4,
    content5: News.ZH.content5,
    content6: News.ZH.content6,
    time1: News.ZH.time1,
    time2: News.ZH.time2,
    time3: News.ZH.time3,
    time4: News.ZH.time4,
    time5: News.ZH.time5,
    time6: News.ZH.time6,

  },
  contact: {
    phone: Contact.ZH.phone,
    fax: Contact.ZH.fax,
    email: Contact.ZH.email,
    address: Contact.ZH.address,
    tel: Contact.ZH.tel,
    faxVal: Contact.ZH.faxVal,
    emailVal: Contact.ZH.emailVal,
    addressVal: Contact.ZH.addressVal,
  }
}
export default zh  //暴露出去