<template>
  <div id="main">
    <el-container>
      <!-- 头 -->
      <el-header height="auto">
        <Header />
      </el-header>
      <!-- 内容 -->
      <el-main height="auto">
        <Main />
      </el-main>
      <!-- 页脚 -->
      <el-footer height="auto"
                 style="padding: 0;">
        <Footer />
      </el-footer>

    </el-container>
  </div>
</template>

<script>
import Header from './header.vue';
import Main from './main.vue';
import Footer from './footer.vue';
export default {
  components: {
    Header, Main, Footer
  }
}
</script>

<style lang="scss" scoped>
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.el-container {
  height: 100%;
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 0 12.5%;
  overflow: visible;
}

@media only screen and (max-width: 500px) {
  #main .el-header {
    position: fixed;
    z-index: 999;
    width: 100%;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .el-main {
    padding: 0;
  }
}
</style>