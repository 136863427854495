const news = {
  ZH: {
    content1: '2023亚洲VIV国际集约化畜牧展VIV Asia',
    content2: '2023国际医药原料中国展览会CPhI China',
    content3: '2023世界制药原料博览会 CPhI Worldwide',
    content4: '2023国际医药原料印度展览会CPhI India',
    time1: '2023年3月8日-3月10日',
    time2: '2023年6月19日-6月21日',
    time3: '2023年10月24日-10月26日',
    time4: '2023年11月29日-12月1日',
  },
  EN: {
    content1: 'VIV Asia',
    content2: 'CPhI China 2023',
    content3: 'CPhI Worldwide 2023',
    content4: 'CPhI India 2023',
    time1: 'March 8-10, 2023',
    time2: 'June 19-21, 2023',
    time3: 'October 24-26, 2023',
    time4: 'November 29-December 1, 2023'
  }
}

export default news