const index = {
  ZH: {  //中文翻译
    carouselL: '兽用原料药及中间体',
    carouselC: '人用原料药及中间体',
    carouselR: '其他在研产品',
    news: '新闻与媒体',
    newsValue: '2023年',
    newsValue2: '国际原料药展会-',
    newsValue3: '欧瑞捷参展动态'
  },
  EN: {  //英文翻译
    carouselL: 'Veterinary APIs & Intermediates',
    carouselC: 'Human APIs & Intermediates',
    carouselR: 'Products in R&D',
    news: 'News & Media',
    newsValue: 'CPhI China in Shanghai',
    newsValue2: 'Jun. 19th-21st, 2023',
    newsValue3: '',

  }
}

export default index