const contact = {
  ZH: {  //中文翻译
    phone: '电话：',
    fax: '传真：',
    email: '邮箱：',
    address: '地址：',
    tel: '+86 592-7073986',
    faxVal: '+86 592-7073986',
    emailVal: 'info@originbiotech.com.cn',
    addressVal: '中国福建省厦门市火炬高新区(翔安)产业区(361101)'
  },
  EN: {  //英文翻译
    phone: 'Tel：',
    fax: 'Fax：',
    email: 'Email：',
    address: 'Address：',
    tel: '+86 592-7073986',
    faxVal: '+86 592-7073986',
    emailVal: 'info@originbiotech.com.cn',
    addressVal: "Xiang'an Torch Hi- tech Industrial Development Zone, Xiamen,Fujian, China(361101)"
  }
}

export default contact
