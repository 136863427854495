const about = {
  ZH: {  //中文翻译
    aboutOrg: '公司简介',
    paragraph1: "厦门欧瑞捷生物科技有限公司，坐落于厦门市火炬高新区翔安产业区，是一家集人用药和兽用药研发、生产与销售为一体的国家级高新技术企业。",
    paragraph2: '目前，公司建成有多个国际高标准的GMP车间；并按照ICH Q7等国际GMP标准建立了完善的质量管理体系，以及配备有先进设备的QC实验室检测体系。在新产品、新技术研发上，公司依托现有研发技术和设备，坚持不断创新，取得了多项科技成果。截至目前已获得发明专利十二项，成果转化证书四项。',
    paragraph3: '公司产品涵盖荷尔蒙类、蛋白及多肽类、前列腺素、小分子化合物类等多类原料药产品。其中，绒促性素、戈那瑞林、促黄体素释放激素A2（丙氨瑞林）、促黄体素释放激素A3（曲普瑞林）、氯前列醇、氯前列醇钠、D-氯前列醇钠、血促性素、布舍瑞林、氨基丁三醇前列腺素F2α、烯丙孕素均已取得GMP证书及生产许可证。',
    paragraph4: '欧瑞捷致力于国内外市场的开拓。凭借卓越的产品质量、注册技术支持及销售服务，公司产品销售量稳居行业前列。截至目前，公司产品销售网络除已覆盖全国之外，大量出口到国际市场，获得了包括欧洲、北美、南美、东南亚、中东等地区和国家的客户一致好评。',
    paragraph5: '此外，为扩大产能，满足客户不断增长的产品需求，公司于2019年在河南省驻马店市高新产业集聚区设立子公司——河南美森药业有限公司，增建原料生产基地（总占地面积53391㎡）。美森药业现已建成多个生产车间，致力于荷尔蒙产品绒促性素、尿促性素、尿促卵泡素、尿激酶、乌司他丁等产品的研发与生产，并积极准备通过FDA及欧盟认证，进一步提升公司在国际市场的品牌力与产品竞争力。'
  },
  EN: {  //英文翻译
    aboutOrg: 'About Origin',
    paragraph1: "Xiamen Origin Biotech Co., Ltd., located in Xiamen Torch Hi-tech Industrial Development Zone, is a ‘State Hi-tech Enterprise’ integrating R&D, manufacturing, and sales of human and veterinary drugs.",
    paragraph2: 'At present, the company has built a number of high-standard GMP workshops, and it has established complete quality management system in accordance with ICH Q7 and QC laboratory equipped with state of the art instruments. In the development of new products and new technologies, the company insists on continuous innovation, relying on brilliant R&D technology and equipment to obtain scientific and technological advances. Up to now, 12 invention patents and 4 achievement transformation certificates have been obtained.',
    paragraph3: "Origin's products include Hormones, Proteins & Peptides, Prostaglandins, Small Molecular Compounds, and other API products, most of which are used in the field of reproduction. Among them, Chorionic Gonadotrophin (HCG), Serum Gonadotrophin (PMSG/ECG), Cloprostenol Sodium, D-Cloprostenol Sodium, Dinoprost Tromethamine, Dinoprost (Prostaglandin F2α), Altrenogest, Gonadorelin Acetate, Luteinizing Hormone Releasing Hormone A2 (Alarelin Acetate), Luteinizing Hormone Releasing Hormone A3 (Triptorelin Acetate), and Pimobendan all obtained GMP certificate.",
    paragraph4: "With excellent product quality, technical support, and sales services, Origin’s products rank in the forefront of domestic market; In the meantime, the company regularly exports to Europe, North America, South America, the Middle East and Asia, and has won unanimous praise from customers both domestic and abroad.",
    paragraph5: "In order to expand production capacity and meet the growing needs of customers, Origin established a subsidiary company--Henan Medscience Pharmaceuticals Co., Ltd. in 2019, which occupies 53391 square meter in the high-tech industrial agglomerate of Zhumadian city. At present, Medscience has built up a series of workshops to produce Chorionic Gonadotrophin (HCG), Menotrophins (HMG), Urofollitrophin (FSH), Urokinase (UK), and Ulinastatin (UTI). At the same time, this subsidiary is actively preparing to apply for FDA and EU GMP, which will further enhance the company's brand recognition and product competitiveness in the international market."
  }
}

export default about