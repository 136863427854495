import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/Layout/index';
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/product-center.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/innovation',
        name: 'RandD',
        component: () => import('@/views/RandD.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/News.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/sales',
        name: 'Sales',
        component: () => import('@/views/Sales.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/duty',
        name: 'Duty',
        component: () => import('@/views/Duty.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/manpower',
        name: 'Power',
        component: () => import('@/views/Manpower.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/callUs',
        name: 'CallUS',
        component: () => import('@/views/Callus.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('@/views/AboutUs.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/devHistory',
        name: 'devHistory',
        component: () => import('@/views/devHistory.vue')
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/honor',
        name: 'Honor',
        component: () => import('@/views/EnterpriseHonor.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
})

export default router
