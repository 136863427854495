import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isZH: localStorage.getItem('lang') == 'zh' ? true : false
  },
  mutations: {
    SWITCH_LANG (state, bool) {
      state.isZH = bool
    }
  }
})