import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
//中英文切换
import ZH from '@/lang/zh.js'  //中文最终汇总暴露的信息
import EN from '@/lang/en.js'  //英文
import VueI18n from 'vue-i18n'
import './assets/font/font.css'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',   //从localStorage里获取用户中英文选择，没有则默认中文
  // locale: 'en',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'en': EN,
    'zh': ZH,
  }
})

import {
  Container,
  Header,
  Main,
  Footer,
  Icon,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Image,
  Row,
  Col,
  Timeline,
  TimelineItem,
  Dialog,
  Button
} from 'element-ui';
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Row);
Vue.use(Col);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Dialog);
Vue.use(Button);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  i18n,
  store
}).$mount('#app')
