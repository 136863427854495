const product = {
  ZH: {  //中文翻译
    Veterinary: '兽用原料药及中间体',
    Human: '人用原料药及中间体'
  },
  EN: {  //英文翻译
    Veterinary: 'Veterinary APIs& Intermediates',
    Human: 'Human APIs& Intermediates'
  }
}

export default product