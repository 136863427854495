<template>
  <div class="header">
    <div class="search">
      <el-row type="flex"
              class="hidden-xs-only">
        <el-col :xs="15"
                :sm="15"
                :md="15"
                :lg="15"
                :xl="14"></el-col>
        <el-col :xs="4"
                :sm="4"
                :md="4"
                :lg="4"
                :xl="5">
          <div style="display: flex;justify-content: flex-end;">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ lang }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="中文">中文</el-dropdown-item>
                <el-dropdown-item command="EN">EN</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-input v-model="searchVal"
                      placeholder="搜索"> <i class="el-input__icon el-icon-search"
                 slot="suffix"
                 @click="searchBtn"></i></el-input>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row type="flex">
      <el-col :xs="0"
              :sm="3"
              :md="3"
              :lg="3"
              :xl="3"></el-col>
      <el-col :xs="24"
              :sm="18"
              :md="18"
              :lg="18"
              :xl="18">
        <div class="sidebar">
          <div class="logo"
               @click="logoClick">
            <img src="@/assets/zhuye/logo.png"
                 style="vertical-align: middle;"
                 alt="">
          </div>
          <div class="hbg"
               @click="hbgClick">
            <img src="~@/assets/M/hbg.png"
                 class="hbg-img"
                 alt="">
          </div>
          <div class="menu hidden-xs-only ">
            <el-menu router
                     :default-active="$route.path"
                     mode="horizontal"
                     active-text-color="#80005c">
              <el-menu-item :index="item.path"
                            v-for="item in menuItems"
                            :class="['6','7'].includes(item.index)&& !$store.state.isZH ? 'show' : ''"
                            :key="item.index">
                <template slot="title">{{ item.value }}</template>
              </el-menu-item>
            </el-menu>
          </div>

        </div>
      </el-col>
      <el-col :xs="0"
              :sm="3"
              :md="3"
              :lg="3"
              :xl="3">
      </el-col>
    </el-row>

    <div class="dialog">
      <el-dialog fullscreen
                 :modal="false"
                 :close-on-click-modal="false"
                 :visible.sync="dialogVisible">
        <template slot="title">
          <div class="icon">
            <img src="../assets/M/MLogo.png"
                 alt=""
                 style="width: 100px; ">
          </div>
        </template>
        <div class="dialog-content">
          <div class="item"
               v-for="item in menuItems "
               @click="dialogMenu(item.path)"
               :class="['6','7'].includes(item.index)&& !$store.state.isZH ? 'show' : ''"
               :key="item.index">

            {{ item.value }}
          </div>
        </div>
        <div slot="footer"
             class="dialog-footer">
          <el-dropdown @command="handleCommand"
                       trigger="click">
            <span style="color: #fff; font-size: 18px;">
              {{ lang }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="中文">中文</el-dropdown-item>
              <el-dropdown-item command="EN">EN</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="icon">
            <i class="el-icon-search"></i>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css';
export default {
  name: 'Header',
  data () {
    return {
      searchVal: '',
      lang: localStorage.getItem('lang') == 'zh' ? '中文' : 'EN',
      activeIndex: '1',
      dialogVisible: false
    }
  },
  computed: {
    menuItems () {
      return [
        {
          index: '1',
          value: this.$t('header.MenuVal1'),
          path: '/'
        },
        {
          index: '2',
          value: this.$t('header.MenuVal2'),
          path: '/product'
        },
        {
          index: '3',
          value: this.$t('header.MenuVal3'),
          path: '/innovation'
        },
        {
          index: '4',
          value: this.$t('header.MenuVal4'),
          path: '/news'
        },
        {
          index: '5',
          value: this.$t('header.MenuVal5'),
          path: '/sales'
        },
        {
          index: '6',
          value: this.$t('header.MenuVal6'),
          path: '/duty'
        },
        {
          index: '7',
          value: this.$t('header.MenuVal7'),
          path: '/manpower'
        },
        {
          index: '8',
          value: this.$t('header.MenuVal8'),
          path: '/callUs'
        },
      ]
    }
  },
  created () {
  },
  methods: {
    searchBtn () {
    },
    handleCommand (command) {
      this.lang = command
      if (command == '中文') {
        this.$i18n.locale = 'zh'
        this.$store.commit('SWITCH_LANG', true)
        localStorage.setItem("lang", "zh")
      } else {
        this.$i18n.locale = 'en'
        this.$store.commit('SWITCH_LANG', false)
        localStorage.setItem("lang", "en")
      }
    },
    logoClick () {
      if (this.$route.path === '/') return
      this.$router.push('/')
    },
    hbgClick () {
      this.dialogVisible = true
    },
    dialogMenu (path) {
      this.dialogVisible = false
      if (this.$route.path == path) return
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown {
  width: 55px;
}
::v-deep .el-dropdown-menu__item {
  font-size: 16px;
}
.show {
  display: none;
}
::v-deep .el-menu {
  display: flex;
  background-color: transparent;
}
::v-deep .el-menu-item {
  font-size: 16px;
}
.header {
  .dialog {
    ::v-deep .el-dialog {
      position: relative;
      background-color: black;
      opacity: 0.9;
    }
    ::v-deep .el-dialog__body {
      color: #fff;
      font-size: 24px;
    }
    ::v-deep .el-dialog__footer {
      position: absolute;
      bottom: 0;
      text-align: center;
      color: #fff;
      width: 100%;
    }
    ::v-deep .el-dialog__headerbtn {
      font-size: 33px;
    }
    .item {
      padding: 3% 0;
      width: 70%;
    }
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        font-size: 22px;
      }
    }
  }
  .search {
    box-sizing: border-box;
    background-color: #5c5c5c;
    display: flex;
    height: 25px;
    width: 100%;
    ::v-deep .el-input {
      width: 125px;
      padding-right: 7px;
    }
    ::v-deep .el-input__inner {
      height: 25px;
      line-height: 25px;
    }
    .el-dropdown-link {
      line-height: 25px;
      cursor: pointer;
      color: #fff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-input__icon {
      height: 25px;
    }
  }

  .sidebar {
    box-sizing: border-box;
    height: 55px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    background-color: rgb(250, 250, 250, 0.9);
    z-index: 99;
    .logo {
      line-height: 55px;
      // width: 104px;
      cursor: pointer;
    }
    .hbg {
      line-height: 55px;
      .hbg-img {
        width: 40px;
        vertical-align: middle;
        display: none;
      }
    }

    .el-menu--horizontal > .el-menu-item {
      border-bottom: none;
      text-align: center;
      line-height: 55px;
      height: 55px;
      color: black;
      font-weight: 600;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: none;
    }
    .el-menu-item,
    .el-submenu__title {
      padding: 0 10px;
    }
  }
}

.el-row {
  width: 100%;
}

@media screen {
}
@media only screen and (max-width: 767px) {
  .header {
    .search {
      background-color: transparent;
      height: 0;
    }
    .el-row--flex {
      display: block;
    }
    .sidebar {
      background-color: transparent;
      line-height: 75px;
      height: 75px;
      justify-content: space-between;
      .hbg {
        line-height: 75px;
        padding-right: 5%;
      }
      .hbg .hbg-img {
        display: inline-block;
      }
      .logo {
        line-height: 75px;
        padding-left: 5%;
      }
    }
  }
}
</style>