import Index from './data/index/index'
import Header from './data/header/index'
import Product from './data/product/index'
import About from './data/AboutUS/index'
import News from './data/news'
import Contact from './data/contact';
const en = {
  language: {
    name: 'EN'
  },
  index: {
    index: Index.EN
  },
  header: {
    header: Header.EN.ARR,
    MenuVal1: Header.EN.MenuVal1,
    MenuVal2: Header.EN.MenuVal2,
    MenuVal3: Header.EN.MenuVal3,
    MenuVal4: Header.EN.MenuVal4,
    MenuVal5: Header.EN.MenuVal5,
    MenuVal6: Header.EN.MenuVal6,
    MenuVal7: Header.EN.MenuVal7,
    MenuVal8: Header.EN.MenuVal8
  },
  product: {
    Veterinary: Product.EN.Veterinary,
    Human: Product.EN.Human,
  },
  about: {
    aboutOrg: About.EN.aboutOrg,
    paragraph1: About.EN.paragraph1,
    paragraph2: About.EN.paragraph2,
    paragraph3: About.EN.paragraph3,
    paragraph4: About.EN.paragraph4,
    paragraph5: About.EN.paragraph5
  },
  news: {
    content1: News.EN.content1,
    content2: News.EN.content2,
    content3: News.EN.content3,
    content4: News.EN.content4,
    content5: News.EN.content5,
    content6: News.EN.content6,
    time1: News.EN.time1,
    time2: News.EN.time2,
    time3: News.EN.time3,
    time4: News.EN.time4,
    time5: News.EN.time5,
    time6: News.EN.time6,

  },
  contact: {
    phone: Contact.EN.phone,
    fax: Contact.EN.fax,
    email: Contact.EN.email,
    address: Contact.EN.address,
    tel: Contact.EN.tel,
    faxVal: Contact.EN.faxVal,
    emailVal: Contact.EN.emailVal,
    addressVal: Contact.EN.addressVal,
  }
}
export default en