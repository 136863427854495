const header = {
  ZH: {  //中文翻译
    MenuVal1: '关于欧瑞捷',
    MenuVal2: '产品中心',
    MenuVal3: '研发与创新',
    MenuVal4: '新闻与媒体',
    MenuVal5: '销售与服务',
    MenuVal6: '社会责任',
    MenuVal7: '人力资源',
    MenuVal8: '联系我们',

  },
  EN: {  //英文翻译
    MenuVal1: 'This is Origin',
    MenuVal2: 'Products',
    MenuVal3: 'R&D',
    MenuVal4: 'News & Media',
    MenuVal5: 'Sales & Service',
    MenuVal6: '社会责任',
    MenuVal7: '人力资源',
    MenuVal8: 'Contact',

  }
}

export default header