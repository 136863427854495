<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.el-container {
  min-height: 100vh;
}
.el-header {
  padding: 0;
}
.footer {
  position: relative;
}
#app {
  height: 100%;
}
</style>
